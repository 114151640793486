@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  &:nth-of-type(odd) {
    background-color: #fafafa;
  }
}

.sectionContent {
  padding: calc(var(--topbarHeightMobile) + 64px) 0;
  position: relative;

  @media (--viewportMedium) {
    padding: calc(var(--topbarHeightDesktop) + 64px) 0;
  }
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.banner {
  position: absolute;
  overflow: hidden;
  min-height: 300px;

  @media (--viewportMedium) {
    border-radius: 24px;
    top: var(--bannerBorderSize);
    right: var(--bannerBorderSize);
    bottom: var(--bannerBorderSize);
    left: var(--bannerBorderSize);
  }
}
