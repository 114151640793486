@import '../../../styles/customMediaQueries.css';

.root {
  margin: calc(var(--topbarHeightMobile) + 12px) 12px;

  @media (--viewportMedium) {
    margin: calc(var(--topbarHeightDesktop) + 28px) 28px;
  }
  @media (--viewportLarge) {
    margin: calc(var(--topbarHeightDesktop) + 50px) 70px;
  }
}
