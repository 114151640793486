@import '../../../../styles/customMediaQueries.css';

.heroWrapper {
  display: grid;
  justify-content: center;
}

.hexagonsWrapper {
  @media (--viewportMedium) {
    width: 93vw;
    max-width: 768px;
  }
  @media (--viewportLarge) {
    width: 80vw;
    max-width: 1440px;
  }
}

.hexagons {
  position: relative;
}

.hero {
  display: grid;
  grid-template-rows: 28px 90px 25px 150px;
  justify-content: center;
  margin-bottom: 70px;

  @media (--viewportMedium) {
    grid-template-rows: 30px 90px 25px 150px;
    margin-bottom: 100px;
  }
  @media (--viewportLarge) {
    grid-template-rows: 60px 90px 25px 150px;
    margin-bottom: 160px;
  }
}

.iconLogo {
  position: absolute;
  top: -76px;
  left: 44px;
  width: 50px;
  height: 42px;
  @media (--viewportMedium) {
    top: -196px;
    left: 76px;
    width: 101px;
    height: 82px;
  }
  @media (--viewportLarge) {
    top: -207px;
    left: 104px;
    width: 122px;
    height: 109px;
  }
}

.bgBase {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  width: 100px;
}

.bgLeft {
  composes: bgBase;
  left: 10px;
  top: -105px;

  @media (--viewportMedium) {
    top: -255px;
    width: 200px;
  }
  @media (--viewportLarge) {
    top: -285px;
    width: 268px;
  }
}

.bgRight {
  composes: bgBase;
  right: 10px;
  top: -100px;

  @media (--viewportMedium) {
    top: -250px;
    width: 190px;
  }
  @media (--viewportLarge) {
    top: -280px;
    right: 10px;
    width: 275px;
  }
}

.titleBase {
  font-family: 'Merriweather';
  font-style: italic;
  color: var(--marketplaceColorDark);
}

.subTitle {
  composes: titleBase;
  text-transform: capitalize;
  font-weight: 900;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  @media (--viewportMedium) {
    font-size: 28px;
    line-height: 35px;
  }
  @media (--viewportLarge) {
    font-size: 32px;
    line-height: 40px;
  }
}

.title {
  composes: titleBase;
  font-weight: 900;
  font-size: 32px;
  line-height: 50px;
  text-align: center;
  @media (--viewportMedium) {
    font-size: 48px;
    line-height: 60px;
  }
  @media (--viewportLarge) {
    font-size: 64px;
    line-height: 80px;
  }
}

.textBold {
  font-weight: 900;
}

.line {
  justify-self: center;
  width: 96px;
  height: 4px;
  background-color: var(--marketplaceColor);
}

.description {
  justify-self: center;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  color: var(--marketplaceColorDark);

  @media (--viewportMedium) {
    width: 436px;
    font-size: 20px;
    line-height: 28px;
  }
  @media (--viewportLarge) {
    font-weight: 400;
  }
}
