@import '../../../../styles/customMediaQueries.css';

.wrapperBase {
  display: grid;
  justify-content: center;
  margin-top: 20px;
  grid-template-rows: 220px 300px;
  gap: 24px;
  margin-bottom: 110px;

  @media (--viewportMedium) {
    grid-template-rows: 410px 210px;
    grid-template-columns: 620px;
    margin: 40px 0 150px 0;
  }
  @media (--viewportLarge) {
    grid-template-rows: 404px;
    margin: 0 0 140px 0;
    gap: 160px;
  }
}

.oddWrapper {
  composes: wrapperBase;

  @media (--viewportLarge) {
    grid-template-columns: 440px 544px;
  }
}

.evenWrapper {
  composes: wrapperBase;
  margin-top: 40px;

  @media (--viewportLarge) {
    grid-template-columns: 544px 440px;
  }
}

.textWrapperBase {
  margin: 0;

  @media (--viewportMedium) {
    display: grid;
    grid-auto-columns: 50px 546px;
    grid-template-rows: 30px 145px;
    gap: 24px;
  }
  @media (--viewportLarge) {
    display: block;
  }
}

.oddTextWrapper {
  composes: textWrapperBase;
  grid-area: 2/1/2/1;
  @media (--viewportMedium) {
    grid-area: 2/1/2/2;
  }
  @media (--viewportLarge) {
    grid-area: 1/1/1/1;
  }
}

.evenTextWrapper {
  composes: textWrapperBase;
  grid-area: 2/1/2/1;
  @media (--viewportMedium) {
    grid-area: 2/1/2/2;
  }
  @media (--viewportLarge) {
    grid-area: 1/2/1/2;
  }
}

.iconWrapper {
  margin-bottom: 20px;
  text-align: center;

  @media (--viewportMedium) {
    grid-area: 1/1/3/1;
    margin: 0;
  }

  @media (--viewportLarge) {
    text-align: left;
  }
}

.numberIcon {
  width: 58px;
  height: 75px;
  color: var(--marketplaceColorDark);
}

.title {
  font-family: 'Merriweather';
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: var(--marketplaceColorDark);
  margin-bottom: 20px;

  @media (--viewportMedium) {
    grid-area: 1/2/2/2;
    text-align: left;
    margin: 0;
    font-size: 28px;
    line-height: 40px;
  }
  @media (--viewportLarge) {
    margin: 12px 0;
  }
}

.highlightText {
  background-color: var(--marketplaceColor);
}

.text {
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  color: var(--defaultTextColor);

  @media (--viewportMedium) {
    grid-area: 2/2/2/2;
    font-size: 16px;
    line-height: 24px;
  }
}

.boldText {
  color: var(--marketplaceColorDark);
  font-weight: 900;
}

.oddImageWrapper {
  grid-area: 1/1/1/1;

  @media (--viewportMedium) {
    grid-area: 1/1/1/1;
  }
  @media (--viewportLarge) {
    grid-area: 1/2/1/2;
  }
}

.evenImageWrapper {
  grid-area: 1/1/1/1;

  @media (--viewportMedium) {
    grid-area: 1/1/1/1;
  }
  @media (--viewportLarge) {
    grid-area: 1/1/1/1;
  }
}

.image {
  pointer-events: none;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.stripeLogo {
  margin-top: 8px;
  margin-left: 5px;
  @media (--viewportMedium) {
    margin-top: 10px;
  }
  @media (--viewportLarge) {
    margin-top: 20px;
    margin-left: 10px;
  }

  & img {
    pointer-events: none;
  }
}
