@import '../../../styles/customMediaQueries.css';

.root {
  margin: calc(var(--topbarHeightMobile) + 90px) 12px 0 12px;

  @media (--viewportMedium) {
    margin: calc(var(--topbarHeightDesktop) + 230px) 28px 0 28px;
  }
  @media (--viewportLarge) {
    margin: calc(var(--topbarHeightDesktop) + 250px) 70px 10px 70px;
  }
}

.callToActions {
  margin: 65px -12px 0 -12px;

  @media (--viewportMedium) {
    margin: 0 -22px;
  }
  @media (--viewportLarge) {
    margin-top: 65px;
  }
}
