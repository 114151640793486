@import '../../../../styles/customMediaQueries.css';

.wrapperBase {
  display: grid;
  justify-content: center;
  margin-top: 20px;
  grid-template-rows: 220px 300px;
  gap: 24px;

  @media (--viewportMedium) {
    grid-template-rows: 425px;
    margin: 40px 0;
  }
  @media (--viewportLarge) {
    grid-template-rows: 520px;
    margin: 45px 0;
    gap: 0;
  }
}

.oddWrapper {
  composes: wrapperBase;
  @media (--viewportMedium) {
    grid-template-columns: 330px 330px;
  }
  @media (--viewportLarge) {
    grid-template-columns: 590px 550px;
  }
}

.evenWrapper {
  composes: wrapperBase;
  margin-top: 40px;

  @media (--viewportMedium) {
    grid-template-columns: 330px 330px;
  }
  @media (--viewportLarge) {
    grid-template-columns: 550px 590px;
  }
}

.textWrapperBase {
  margin: 0;

  @media (--viewportLarge) {
    margin: 85px 90px 0 67px;
  }
}

.oddTextWrapper {
  composes: textWrapperBase;
  grid-area: 2/1/2/1;
  @media (--viewportMedium) {
    grid-area: 1/1/1/1;
  }
}

.evenTextWrapper {
  composes: textWrapperBase;
  grid-area: 2/1/2/1;
  @media (--viewportMedium) {
    grid-area: 1/2/1/2;
  }
}

.title {
  font-family: 'Merriweather';
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: var(--marketplaceColorDark);
  margin-bottom: 8px;

  @media (--viewportMedium) {
    text-align: left;
    margin-bottom: 12px;
  }
  @media (--viewportLarge) {
    font-size: 28px;
    line-height: 35px;
  }
}

.text {
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  color: var(--defaultTextColor);

  @media (--viewportLarge) {
    font-size: 16px;
    line-height: 24px;
  }
}

.lineWrapper {
  display: flex;
  justify-content: center;
  @media (--viewportMedium) {
    justify-content: start;
  }
}

.line {
  margin: 20px 0;
  width: 56px;
  height: 4px;
  background-color: var(--marketplaceColor);

  @media (--viewportLarge) {
    margin: 24px 0;
    width: 60px;
    height: 5px;
  }
}

.categories {
  display: flex;
  grid-template-columns: repeat(3, minmax(105px, 1fr));
  gap: 10px;
  justify-content: center;

  @media (--viewportMedium) {
    grid-template-columns: repeat(3, minmax(112px, 1fr));
    gap: 12px;
    justify-content: start;
  }
  @media (--viewportLarge) {
    grid-template-columns: repeat(3, minmax(134px, 1fr));
  }
}

.oddImageWrapper {
  grid-area: 1/1/1/1;

  @media (--viewportMedium) {
    grid-area: 1/2/1/2;
  }
}

.evenImageWrapper {
  grid-area: 1/1/1/1;
}

.image {
  pointer-events: none;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  text-align: center;
  border: 1px solid var(--rentalCategoriesDefaultColor);
  font-size: 14px;
  line-height: 18px;
  width: 105px;
  height: 112px;
  color: var(--marketplaceColorDark);

  &:hover {
    border-color: var(--marketplaceColor);
    text-decoration: none;
    & > *:first-child {
      color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    width: 112px;
  }

  @media (--viewportLarge) {
    width: 134px;
    height: 130px;
  }
}

.categoryIcon {
  margin: 0 0 12px 0;
  width: 32px;
  height: 32px;
  color: var(--rentalCategoriesDefaultColor);

  &:hover {
    color: var(--marketplaceColor);
  }

  @media (--viewportLarge) {
    width: 48px;
    height: 48px;
  }
}
