@import '../../../../styles/customMediaQueries.css';

.categories {
  display: grid;
  justify-content: center;
  margin-top: 100px;
}

.categoriesWrappers {
  display: grid;
  justify-content: center;
}

.title {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 8px;

  font-weight: 800;
  font-size: 12px;
  line-height: 15px;

  @media (--viewportLarge) {
    font-size: 14px;
    line-height: 18px;
  }
}

.listWrapper {
  display: grid;
  grid-template-columns: repeat(var(--additionalСategoriesGridColumns), minmax(105px, 1fr));
  justify-content: center;
  gap: 10px;

  & a:last-child {
    border: 1px solid var(--marketplaceColor);
    &:hover {
      & > * {
        color: var(--marketplaceColorDark);
      }
    }
  }

  @media (--viewportMedium) {
    grid-template-columns: repeat(var(--additionalСategoriesGridColumns), minmax(112px, 1fr));
    gap: 12px;
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(var(--additionalСategoriesGridColumns), minmax(134px, 1fr));
  }
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  text-align: center;
  border: 1px solid var(--rentalCategoriesDefaultColor);
  font-size: 14px;
  line-height: 18px;
  width: 105px;
  height: 112px;

  color: var(--marketplaceColorDark);

  &:hover {
    border-color: var(--marketplaceColor);
    text-decoration: none;
    & > *:first-child {
      color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    width: 112px;
  }

  @media (--viewportLarge) {
    width: 134px;
    height: 130px;
  }
}

.categoryIcon {
  margin: 0 0 12px 0;
  width: 32px;
  height: 32px;
  color: var(--rentalCategoriesDefaultColor);

  &:hover {
    color: var(--marketplaceColor);
  }

  @media (--viewportLarge) {
    width: 48px;
    height: 48px;
  }
}
