@import '../../../../styles/customMediaQueries.css';

.baseColumn {
  max-width: var(--contentMaxWidth);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;
}

.oneColumn {
  composes: baseColumn;
}

.twoColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(2, calc((100% - 32px) / 2));
  }
}

.threeColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(3, calc((100% - 2 * 32px) / 3));
  }
}

.fourColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(4, calc((100% - 3 * 32px) / 4));
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.logo {
  width: 40px;
  height: 24px;
  color: var(--marketplaceColor);
  justify-self: center;
  margin: 57px 0 17px 0;
}

.bannerTitle {
  color: var(--matterColorLight);
  text-align: center;
}

.bannerDescription {
  color: var(--marketplaceColorLight);
  text-align: center;

  @media (--viewportMedium) {
    font-size: 14px;
    line-height: 20px;
    width: 432px;
  }
}

.bannerButtons {
  grid-template-columns: repeat(2, calc((100% - 32px) / 2));
  padding-top: 0;
  gap: 12px;
  max-width: 390px;

  & > *:first-child {
    text-align: right;
    & > div a {
      min-width: 140px;
      text-align: center;
      white-space: nowrap;
      border: 1px solid var(--marketplaceColor);
      &:hover {
        color: var(--marketplaceColor);
      }
    }
  }

  & > *:nth-child(2) {
    text-align: left;
    & > div a {
      min-width: 140px;
      text-align: center;
      white-space: nowrap;
      border: 1px solid var(--marketplaceColor);
      background-color: var(--matterColorDark);
      color: var(--marketplaceColor);
      &:hover {
        background-color: var(--marketplaceColor);
        color: var(--matterColorDark);
      }
    }
  }
}
