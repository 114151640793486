@import '../../../../styles/customMediaQueries.css';

.contentWrapper {
  display: grid;
  gap: 50px;
  justify-content: center;
  padding-top: 85px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 350px);
    gap: 65px;
    padding: 0 0 130px 0;
  }
  @media (--viewportLarge) {
    grid-template-columns: repeat(2, 500px);
    gap: 150px;
  }
}

.blockWrapper {
  display: grid;
  grid-template-rows: 86px 53px 18px 100px;
}

.icon {
  color: var(--marketplaceColor);
  width: 72px;
  height: 72px;
  justify-self: center;

  @media (--viewportMedium) {
    justify-self: flex-start;
  }
}

.title {
  font-family: 'Merriweather';
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  justify-self: center;
  color: var(--marketplaceColorDark);

  @media (--viewportMedium) {
    font-size: 28px;
    line-height: 40px;
    justify-self: flex-start;
  }
}

.line {
  width: 57px;
  height: 5px;
  background-color: var(--marketplaceColor);
  justify-self: center;

  @media (--viewportMedium) {
    justify-self: flex-start;
  }
}

.text {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: var(--defaultTextColor);
}
