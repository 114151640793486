@import '../../../../styles/customMediaQueries.css';

.hero {
  display: grid;
  justify-content: center;
}

.heroWrapper {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr;
  grid-template-rows: 312px 270px;
  overflow: hidden;

  background-color: var(--marketplaceColor);
  border-radius: var(--borderRadiusEventHero);

  @media (--viewportMedium) {
    grid-template-rows: 364px 192px;
  }
  @media (--viewportLarge) {
    grid-template-columns: 497px 643px;
    grid-template-rows: 552px;
  }
}

.textsWrapper {
  display: grid;
  align-content: space-between;
  grid-area: 2/1/2/1;
  margin: 10px 20px 48px 20px;

  @media (--viewportMedium) {
    margin: 10px 110px 0 110px;
  }
  @media (--viewportLarge) {
    grid-area: 1/1/1/1;
    margin: 80px 90px 40px 42px;
  }
}

.trapezoidWrapper {
  grid-area: 1/1/1/1;
  position: relative;
  margin-top: -28px;
  display: flex;
  justify-content: center;

  @media (--viewportLarge) {
    display: none;
  }
}

.trapezoid {
  border-bottom: 20px solid var(--marketplaceColor);
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  height: 0;
  width: 192px;
  @media (--viewportLarge) {
    display: none;
  }
}

.subTitle {
  grid-area: 1/1/1/1;
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  text-transform: uppercase;
  color: var(--marketplaceColorDark);
  text-align: center;

  @media (--viewportLarge) {
    text-align: left;
  }
}

.title {
  font-family: 'Merriweather';
  font-style: italic;
  color: var(--marketplaceColorDark);
  font-weight: 900;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  margin-bottom: 8px;

  @media (--viewportMedium) {
    margin: 4px 0 8px 0;
    font-size: 40px;
    line-height: 50px;
  }
  @media (--viewportLarge) {
    margin: 0 0 12px -10px;
    text-align: left;
    font-weight: 700;
    font-size: 72px;
    line-height: 80px;
  }
}

.description {
  color: var(--marketplaceColorDark);
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;

  @media (--viewportMedium) {
    font-size: 15px;
    line-height: 22px;
  }
  @media (--viewportLarge) {
    font-size: 16px;
    line-height: 24px;
  }
}

.imageWrapper {
  display: flex;
  justify-content: center;
  grid-area: 1/1/1/1;
  overflow: hidden;

  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    grid-area: 1/2/1/2;

    &:hover img {
      transform: scale(1.1);
    }
  }
}

.imageHero {
  object-fit: cover;
  width: 100%;
  height: 100%;

  pointer-events: none;
  transition: transform 0.5s;

  @media (--viewportLarge) {
    object-fit: contain;
  }
}

.letterLogo {
  display: none;

  @media (--viewportLarge) {
    position: relative;
    display: flex;
    max-width: 32px;
    height: 32px;
    border-radius: 2px;
  }
  .logo {
    position: absolute;
    top: 8px;
    left: 5px;
    width: 23px;
    height: 14px;
    color: var(--marketplaceColor);
  }
}
