@import '../../styles/customMediaQueries.css';

.layout {
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

.main {
  display: grid;
}

.footer {
  margin-top: -130px;
  @media (--viewportMedium) {
    margin-top: -210px;
  }
  @media (--viewportLarge) {
    margin: 0;
  }
}
